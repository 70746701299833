import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent {
  constructor() {
    this.scrollToCurrentAnchor();
  }

  scrollToCurrentAnchor() {
    // todo: there may be a better way to do this, but this works for now...
    setTimeout(() => {
      try {
        const anchor = window.location.hash;
        if (!anchor) {
          return;
        }
        const element = document.querySelector(anchor);
        element?.scrollIntoView({ behavior: 'smooth' });
      } catch (err) {
        // ignore
      }
    }, 100);
  }
}
